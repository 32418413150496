import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_r7ltImJ0os from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia-orm/nuxt/dist/runtime/plugin.vue3.js";
import nodeup_sentry_GDvIFk6K3l from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-sentry.ts";
import nodeup_disable_console_log_e8rQwG3cs7 from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-disable-console-log.js";
import nodeup_error_9k0OsruaQO from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-error.js";
import nodeup_graphql_request_POFOCaWk4Z from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-graphql-request.js";
import nodeup_auth_c0LYnqxJf0 from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-auth.js";
import vuetify_7Prac0A2JL from "/opt/atlassian/pipelines/agent/build/client/plugins/vuetify.ts";
import nodeup_classificators_qHMI3lODuI from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-classificators.js";
import nodeup_utils_xnm0sdUWoF from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-utils.js";
import nodeup_is_mobile_vF1qcDAR0R from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-is-mobile.js";
import nodeup_intercom_dPLlFJjijk from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-intercom.js";
import nodeup_reload_6OM5rQGroV from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-reload.js";
import nodeup_websocket_updates_3b5amJ5QEU from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-websocket-updates.js";
import nodeup_directives_UNRIqhivo0 from "/opt/atlassian/pipelines/agent/build/client/plugins/nodeup-directives.js";
import dayjs_g3asnNCYsI from "/opt/atlassian/pipelines/agent/build/client/plugins/dayjs.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_r7ltImJ0os,
  nodeup_sentry_GDvIFk6K3l,
  nodeup_disable_console_log_e8rQwG3cs7,
  nodeup_error_9k0OsruaQO,
  nodeup_graphql_request_POFOCaWk4Z,
  nodeup_auth_c0LYnqxJf0,
  vuetify_7Prac0A2JL,
  nodeup_classificators_qHMI3lODuI,
  nodeup_utils_xnm0sdUWoF,
  nodeup_is_mobile_vF1qcDAR0R,
  nodeup_intercom_dPLlFJjijk,
  nodeup_reload_6OM5rQGroV,
  nodeup_websocket_updates_3b5amJ5QEU,
  nodeup_directives_UNRIqhivo0,
  dayjs_g3asnNCYsI
]