import { gql } from 'graphql-request';
import { BaseModel } from './BaseModel';
import { useRepo } from 'pinia-orm';

export default class Me extends BaseModel {

    static entity = 'me'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            email: this.string(''),
            ip: this.string('')
        }
    }

    static getData() {
        let res = super.getItems();
        return res.length ? res[0] : {};
    }

    static async apiFetchSingle() {
        const query = gql`
            query getMyData {
                me {
                    id
                    name
                    email
                }
            }
        `
        const res = await window.$graphQLQuery(query, {})
        if (res && res.me) {
            useRepo(this).save(res.me)
        }
        return res
    }

    static async apiFetchMyIp() {
        var meData = useRepo(this).query().first()
        if (meData && meData.ip) {
            return meData.ip
        }
        const query = gql`
            query userIp {
                userIp
            }
        `
        const res = await window.$graphQLQuery(query, {})
        var meData = useRepo(this).query().first()
        useRepo(this).save({ id: meData.id, ip: res.userIp })
        return res.userIp
    }

    static async apiUpdate(params) {
        const query = gql`
            mutation updateMe($me: UpdateMeInput!) {
                updateMe(project: $me){
                    id
                    name
                    email
                }
            }
        `
        const res = await window.$graphQLQuery(query, params)
        if (res && res.updateMe) {
            await useRepo(this).save(res.updateMe)
        }
        return res
    }
}