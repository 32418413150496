import { gql } from 'graphql-request'
import { BaseModel } from './BaseModel';

export default class User extends BaseModel {

    static entity = 'users'

    static fields() {
        return {
            id: this.uid(),
            name: this.string(''),
            email: this.string(''),
        }
    }

    static async apiInsert(params) {
        const query = gql`
            mutation createUser($user: CreateUserInput!) {
                createUser(user: $user){
                    id
                }
            }
        `
        const isAuthQuery = true
        return await window.$graphQLQuery(query, params, isAuthQuery)
    }

    static async apiConfirmEmail(params) {
        const query = gql`
            mutation confirmUserEmail($confirmUserEmail: ConfirmEmailInput!) {
                confirmUserEmail(confirmUserEmail: $confirmUserEmail)
            }
        `
        const isAuthQuery = true
        return await window.$graphQLQuery(query, { 'confirmUserEmail': params }, isAuthQuery)
    }

    static async apiForgotUserPassword(params) {
        const query = gql`
            mutation forgotUserPassword($forgotUserPassword: ForgotUserPasswordInput!) {
                forgotUserPassword(forgotUserPassword: $forgotUserPassword)
            }
        `
        const isAuthQuery = true
        return await window.$graphQLQuery(query, { 'forgotUserPassword': params }, isAuthQuery)
    }

    static async apiChangeUserPassword(params) {
        const query = gql`
            mutation changeUserPassword($changeUserPassword: ChangeUserPasswordInput!) {
                changeUserPassword(changeUserPassword: $changeUserPassword)
            }
        `
        const isAuthQuery = true
        return await window.$graphQLQuery(query, { 'changeUserPassword': params }, isAuthQuery)
    }
}
