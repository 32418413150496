import { default as apikeysGSs6BF0eFXMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/account/apikeys.vue?macro=true";
import { default as indexIBtAhMsiiLMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/account/index.vue?macro=true";
import { default as sshkeysg1mCUFxaOIMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/account/sshkeys.vue?macro=true";
import { default as accountmlX9YSrRHKMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/account.vue?macro=true";
import { default as componentswpXE5W7RvJMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/apps/[id]/components.vue?macro=true";
import { default as indexVmxjeNhBhpMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/apps/[id]/index.vue?macro=true";
import { default as _91id_93TsmT7K5HxaMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/apps/[id].vue?macro=true";
import { default as indexI2gDWJhsw6Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/apps/index.vue?macro=true";
import { default as estimatedcost9TovS70lYzMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing/estimatedcost.vue?macro=true";
import { default as indexyFMUUcWo5lMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing/index.vue?macro=true";
import { default as ordersA0PmkQrKZbMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing/orders.vue?macro=true";
import { default as settingszTda9QbMb6Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing/settings.vue?macro=true";
import { default as billingbU6ui3BElUMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/billing.vue?macro=true";
import { default as emailw2AlzVPN8EMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/confirm/email.vue?macro=true";
import { default as indexm8qzlh0rTDMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/confirm/index.vue?macro=true";
import { default as contactssCmtXTOzrGMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/contacts.vue?macro=true";
import { default as dnsrecordsHHMZ2dsg1TMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/dnsrecords.vue?macro=true";
import { default as indexI2XwZFvBesMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/index.vue?macro=true";
import { default as nameserversvxc3C5k67AMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/nameservers.vue?macro=true";
import { default as _91id_93n1iJS81GCPMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/[id].vue?macro=true";
import { default as indexbkaB2E46TAMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/index.vue?macro=true";
import { default as newQ1n9RwF54zMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/new.vue?macro=true";
import { default as thankyouRnKF1vHkJSMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/domains/thankyou.vue?macro=true";
import { default as forgotQKZorVLVbpMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/forgot.vue?macro=true";
import { default as googleXfoXShBmugMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/google.vue?macro=true";
import { default as indexWSUnvUlpTrMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/index.vue?macro=true";
import { default as _91id_93qlrZpBw3i8Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/invoices/[id].vue?macro=true";
import { default as login3Rbyp3w8rKMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/login.vue?macro=true";
import { default as montonio_45notifyCWr15VatPJMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/montonio-notify.vue?macro=true";
import { default as activities3zrX0OBLhLMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/activities.vue?macro=true";
import { default as indexERFbgob45nMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/index.vue?macro=true";
import { default as settingsQk9X765gXMMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/settings.vue?macro=true";
import { default as _91id_93IcGc1TBCpeMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/[id].vue?macro=true";
import { default as _0mg3e82oexZMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/0.vue?macro=true";
import { default as indexXHsyemcQToMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/projects/index.vue?macro=true";
import { default as apinlYDLUFfPkMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/settings/api.vue?macro=true";
import { default as index1TFraJLRwbMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/settings/index.vue?macro=true";
import { default as teamWx8BR9BI37Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/settings/team.vue?macro=true";
import { default as settingspVtfylRpmDMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/settings.vue?macro=true";
import { default as signupkffDEuh49XMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/signup.vue?macro=true";
import { default as backupsQkeufevDCHMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/backups.vue?macro=true";
import { default as basicauthsn7n6KNsygAMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/basicauths.vue?macro=true";
import { default as cronjobsuo1B31S5mRMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/cronjobs.vue?macro=true";
import { default as databasesEyI87ug9pOMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/databases.vue?macro=true";
import { default as emaillistsFTqcIzImIDMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/emaillists.vue?macro=true";
import { default as emailsMNuNNX6u4KMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/emails.vue?macro=true";
import { default as ftpsbziApEYntdMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/ftps.vue?macro=true";
import { default as index98BJULjDHbMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/index.vue?macro=true";
import { default as sshCfJJBOZ84WMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/ssh.vue?macro=true";
import { default as subdomainsWZLVvb47RjMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/subdomains.vue?macro=true";
import { default as _91id_93xHj4i199pzMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id].vue?macro=true";
import { default as indexoHxpw8zg3gMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/index.vue?macro=true";
import { default as console88vlj67FYxMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/console.vue?macro=true";
import { default as indexnAyMceUnuQMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/index.vue?macro=true";
import { default as statisticsYzVRzaHbwYMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/statistics.vue?macro=true";
import { default as _91id_93IAALiv3YjcMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/[id].vue?macro=true";
import { default as index8dPmd9SuiQMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vms/index.vue?macro=true";
import { default as indexdOK6fc9BwIMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/index.vue?macro=true";
import { default as networksvKn4dxZXWZMeta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/networks.vue?macro=true";
import { default as usersJRa2yalCZ7Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/users.vue?macro=true";
import { default as _91id_93T7jqGRynR7Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id].vue?macro=true";
import { default as indexBNzKFczix9Meta } from "/opt/atlassian/pipelines/agent/build/client/pages/vpns/index.vue?macro=true";
export default [
  {
    name: accountmlX9YSrRHKMeta?.name ?? undefined,
    path: accountmlX9YSrRHKMeta?.path ?? "/account",
    meta: accountmlX9YSrRHKMeta || {},
    alias: accountmlX9YSrRHKMeta?.alias || [],
    redirect: accountmlX9YSrRHKMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: apikeysGSs6BF0eFXMeta?.name ?? "account-apikeys",
    path: apikeysGSs6BF0eFXMeta?.path ?? "apikeys",
    meta: apikeysGSs6BF0eFXMeta || {},
    alias: apikeysGSs6BF0eFXMeta?.alias || [],
    redirect: apikeysGSs6BF0eFXMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/account/apikeys.vue").then(m => m.default || m)
  },
  {
    name: indexIBtAhMsiiLMeta?.name ?? "account",
    path: indexIBtAhMsiiLMeta?.path ?? "",
    meta: indexIBtAhMsiiLMeta || {},
    alias: indexIBtAhMsiiLMeta?.alias || [],
    redirect: indexIBtAhMsiiLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: sshkeysg1mCUFxaOIMeta?.name ?? "account-sshkeys",
    path: sshkeysg1mCUFxaOIMeta?.path ?? "sshkeys",
    meta: sshkeysg1mCUFxaOIMeta || {},
    alias: sshkeysg1mCUFxaOIMeta?.alias || [],
    redirect: sshkeysg1mCUFxaOIMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/account/sshkeys.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93TsmT7K5HxaMeta?.name ?? undefined,
    path: _91id_93TsmT7K5HxaMeta?.path ?? "/apps/:id()",
    meta: _91id_93TsmT7K5HxaMeta || {},
    alias: _91id_93TsmT7K5HxaMeta?.alias || [],
    redirect: _91id_93TsmT7K5HxaMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/apps/[id].vue").then(m => m.default || m),
    children: [
  {
    name: componentswpXE5W7RvJMeta?.name ?? "apps-id-components",
    path: componentswpXE5W7RvJMeta?.path ?? "components",
    meta: componentswpXE5W7RvJMeta || {},
    alias: componentswpXE5W7RvJMeta?.alias || [],
    redirect: componentswpXE5W7RvJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/apps/[id]/components.vue").then(m => m.default || m)
  },
  {
    name: indexVmxjeNhBhpMeta?.name ?? "apps-id",
    path: indexVmxjeNhBhpMeta?.path ?? "",
    meta: indexVmxjeNhBhpMeta || {},
    alias: indexVmxjeNhBhpMeta?.alias || [],
    redirect: indexVmxjeNhBhpMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/apps/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexI2gDWJhsw6Meta?.name ?? "apps",
    path: indexI2gDWJhsw6Meta?.path ?? "/apps",
    meta: indexI2gDWJhsw6Meta || {},
    alias: indexI2gDWJhsw6Meta?.alias || [],
    redirect: indexI2gDWJhsw6Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/apps/index.vue").then(m => m.default || m)
  },
  {
    name: billingbU6ui3BElUMeta?.name ?? undefined,
    path: billingbU6ui3BElUMeta?.path ?? "/billing",
    meta: billingbU6ui3BElUMeta || {},
    alias: billingbU6ui3BElUMeta?.alias || [],
    redirect: billingbU6ui3BElUMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing.vue").then(m => m.default || m),
    children: [
  {
    name: estimatedcost9TovS70lYzMeta?.name ?? "billing-estimatedcost",
    path: estimatedcost9TovS70lYzMeta?.path ?? "estimatedcost",
    meta: estimatedcost9TovS70lYzMeta || {},
    alias: estimatedcost9TovS70lYzMeta?.alias || [],
    redirect: estimatedcost9TovS70lYzMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing/estimatedcost.vue").then(m => m.default || m)
  },
  {
    name: indexyFMUUcWo5lMeta?.name ?? "billing",
    path: indexyFMUUcWo5lMeta?.path ?? "",
    meta: indexyFMUUcWo5lMeta || {},
    alias: indexyFMUUcWo5lMeta?.alias || [],
    redirect: indexyFMUUcWo5lMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing/index.vue").then(m => m.default || m)
  },
  {
    name: ordersA0PmkQrKZbMeta?.name ?? "billing-orders",
    path: ordersA0PmkQrKZbMeta?.path ?? "orders",
    meta: ordersA0PmkQrKZbMeta || {},
    alias: ordersA0PmkQrKZbMeta?.alias || [],
    redirect: ordersA0PmkQrKZbMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing/orders.vue").then(m => m.default || m)
  },
  {
    name: settingszTda9QbMb6Meta?.name ?? "billing-settings",
    path: settingszTda9QbMb6Meta?.path ?? "settings",
    meta: settingszTda9QbMb6Meta || {},
    alias: settingszTda9QbMb6Meta?.alias || [],
    redirect: settingszTda9QbMb6Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/billing/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: emailw2AlzVPN8EMeta?.name ?? "confirm-email",
    path: emailw2AlzVPN8EMeta?.path ?? "/confirm/email",
    meta: emailw2AlzVPN8EMeta || {},
    alias: emailw2AlzVPN8EMeta?.alias || [],
    redirect: emailw2AlzVPN8EMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/confirm/email.vue").then(m => m.default || m)
  },
  {
    name: indexm8qzlh0rTDMeta?.name ?? "confirm",
    path: indexm8qzlh0rTDMeta?.path ?? "/confirm",
    meta: indexm8qzlh0rTDMeta || {},
    alias: indexm8qzlh0rTDMeta?.alias || [],
    redirect: indexm8qzlh0rTDMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93n1iJS81GCPMeta?.name ?? undefined,
    path: _91id_93n1iJS81GCPMeta?.path ?? "/domains/:id()",
    meta: _91id_93n1iJS81GCPMeta || {},
    alias: _91id_93n1iJS81GCPMeta?.alias || [],
    redirect: _91id_93n1iJS81GCPMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id].vue").then(m => m.default || m),
    children: [
  {
    name: contactssCmtXTOzrGMeta?.name ?? "domains-id-contacts",
    path: contactssCmtXTOzrGMeta?.path ?? "contacts",
    meta: contactssCmtXTOzrGMeta || {},
    alias: contactssCmtXTOzrGMeta?.alias || [],
    redirect: contactssCmtXTOzrGMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/contacts.vue").then(m => m.default || m)
  },
  {
    name: dnsrecordsHHMZ2dsg1TMeta?.name ?? "domains-id-dnsrecords",
    path: dnsrecordsHHMZ2dsg1TMeta?.path ?? "dnsrecords",
    meta: dnsrecordsHHMZ2dsg1TMeta || {},
    alias: dnsrecordsHHMZ2dsg1TMeta?.alias || [],
    redirect: dnsrecordsHHMZ2dsg1TMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/dnsrecords.vue").then(m => m.default || m)
  },
  {
    name: indexI2XwZFvBesMeta?.name ?? "domains-id",
    path: indexI2XwZFvBesMeta?.path ?? "",
    meta: indexI2XwZFvBesMeta || {},
    alias: indexI2XwZFvBesMeta?.alias || [],
    redirect: indexI2XwZFvBesMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: nameserversvxc3C5k67AMeta?.name ?? "domains-id-nameservers",
    path: nameserversvxc3C5k67AMeta?.path ?? "nameservers",
    meta: nameserversvxc3C5k67AMeta || {},
    alias: nameserversvxc3C5k67AMeta?.alias || [],
    redirect: nameserversvxc3C5k67AMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/[id]/nameservers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexbkaB2E46TAMeta?.name ?? "domains",
    path: indexbkaB2E46TAMeta?.path ?? "/domains",
    meta: indexbkaB2E46TAMeta || {},
    alias: indexbkaB2E46TAMeta?.alias || [],
    redirect: indexbkaB2E46TAMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/index.vue").then(m => m.default || m)
  },
  {
    name: newQ1n9RwF54zMeta?.name ?? "domains-new",
    path: newQ1n9RwF54zMeta?.path ?? "/domains/new",
    meta: newQ1n9RwF54zMeta || {},
    alias: newQ1n9RwF54zMeta?.alias || [],
    redirect: newQ1n9RwF54zMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/new.vue").then(m => m.default || m)
  },
  {
    name: thankyouRnKF1vHkJSMeta?.name ?? "domains-thankyou",
    path: thankyouRnKF1vHkJSMeta?.path ?? "/domains/thankyou",
    meta: thankyouRnKF1vHkJSMeta || {},
    alias: thankyouRnKF1vHkJSMeta?.alias || [],
    redirect: thankyouRnKF1vHkJSMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/domains/thankyou.vue").then(m => m.default || m)
  },
  {
    name: forgotQKZorVLVbpMeta?.name ?? "forgot",
    path: forgotQKZorVLVbpMeta?.path ?? "/forgot",
    meta: forgotQKZorVLVbpMeta || {},
    alias: forgotQKZorVLVbpMeta?.alias || [],
    redirect: forgotQKZorVLVbpMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: googleXfoXShBmugMeta?.name ?? "google",
    path: googleXfoXShBmugMeta?.path ?? "/google",
    meta: googleXfoXShBmugMeta || {},
    alias: googleXfoXShBmugMeta?.alias || [],
    redirect: googleXfoXShBmugMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/google.vue").then(m => m.default || m)
  },
  {
    name: indexWSUnvUlpTrMeta?.name ?? "index",
    path: indexWSUnvUlpTrMeta?.path ?? "/",
    meta: indexWSUnvUlpTrMeta || {},
    alias: indexWSUnvUlpTrMeta?.alias || [],
    redirect: indexWSUnvUlpTrMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qlrZpBw3i8Meta?.name ?? "invoices-id",
    path: _91id_93qlrZpBw3i8Meta?.path ?? "/invoices/:id()",
    meta: _91id_93qlrZpBw3i8Meta || {},
    alias: _91id_93qlrZpBw3i8Meta?.alias || [],
    redirect: _91id_93qlrZpBw3i8Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/invoices/[id].vue").then(m => m.default || m)
  },
  {
    name: login3Rbyp3w8rKMeta?.name ?? "login",
    path: login3Rbyp3w8rKMeta?.path ?? "/login",
    meta: login3Rbyp3w8rKMeta || {},
    alias: login3Rbyp3w8rKMeta?.alias || [],
    redirect: login3Rbyp3w8rKMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: montonio_45notifyCWr15VatPJMeta?.name ?? "montonio-notify",
    path: montonio_45notifyCWr15VatPJMeta?.path ?? "/montonio-notify",
    meta: montonio_45notifyCWr15VatPJMeta || {},
    alias: montonio_45notifyCWr15VatPJMeta?.alias || [],
    redirect: montonio_45notifyCWr15VatPJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/montonio-notify.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IcGc1TBCpeMeta?.name ?? undefined,
    path: _91id_93IcGc1TBCpeMeta?.path ?? "/projects/:id()",
    meta: _91id_93IcGc1TBCpeMeta || {},
    alias: _91id_93IcGc1TBCpeMeta?.alias || [],
    redirect: _91id_93IcGc1TBCpeMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/[id].vue").then(m => m.default || m),
    children: [
  {
    name: activities3zrX0OBLhLMeta?.name ?? "projects-id-activities",
    path: activities3zrX0OBLhLMeta?.path ?? "activities",
    meta: activities3zrX0OBLhLMeta || {},
    alias: activities3zrX0OBLhLMeta?.alias || [],
    redirect: activities3zrX0OBLhLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/activities.vue").then(m => m.default || m)
  },
  {
    name: indexERFbgob45nMeta?.name ?? "projects-id",
    path: indexERFbgob45nMeta?.path ?? "",
    meta: indexERFbgob45nMeta || {},
    alias: indexERFbgob45nMeta?.alias || [],
    redirect: indexERFbgob45nMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: settingsQk9X765gXMMeta?.name ?? "projects-id-settings",
    path: settingsQk9X765gXMMeta?.path ?? "settings",
    meta: settingsQk9X765gXMMeta || {},
    alias: settingsQk9X765gXMMeta?.alias || [],
    redirect: settingsQk9X765gXMMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/[id]/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _0mg3e82oexZMeta?.name ?? "projects-0",
    path: _0mg3e82oexZMeta?.path ?? "/projects/0",
    meta: _0mg3e82oexZMeta || {},
    alias: _0mg3e82oexZMeta?.alias || [],
    redirect: _0mg3e82oexZMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/0.vue").then(m => m.default || m)
  },
  {
    name: indexXHsyemcQToMeta?.name ?? "projects",
    path: indexXHsyemcQToMeta?.path ?? "/projects",
    meta: indexXHsyemcQToMeta || {},
    alias: indexXHsyemcQToMeta?.alias || [],
    redirect: indexXHsyemcQToMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: settingspVtfylRpmDMeta?.name ?? undefined,
    path: settingspVtfylRpmDMeta?.path ?? "/settings",
    meta: settingspVtfylRpmDMeta || {},
    alias: settingspVtfylRpmDMeta?.alias || [],
    redirect: settingspVtfylRpmDMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: apinlYDLUFfPkMeta?.name ?? "settings-api",
    path: apinlYDLUFfPkMeta?.path ?? "api",
    meta: apinlYDLUFfPkMeta || {},
    alias: apinlYDLUFfPkMeta?.alias || [],
    redirect: apinlYDLUFfPkMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/settings/api.vue").then(m => m.default || m)
  },
  {
    name: index1TFraJLRwbMeta?.name ?? "settings",
    path: index1TFraJLRwbMeta?.path ?? "",
    meta: index1TFraJLRwbMeta || {},
    alias: index1TFraJLRwbMeta?.alias || [],
    redirect: index1TFraJLRwbMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: teamWx8BR9BI37Meta?.name ?? "settings-team",
    path: teamWx8BR9BI37Meta?.path ?? "team",
    meta: teamWx8BR9BI37Meta || {},
    alias: teamWx8BR9BI37Meta?.alias || [],
    redirect: teamWx8BR9BI37Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/settings/team.vue").then(m => m.default || m)
  }
]
  },
  {
    name: signupkffDEuh49XMeta?.name ?? "signup",
    path: signupkffDEuh49XMeta?.path ?? "/signup",
    meta: signupkffDEuh49XMeta || {},
    alias: signupkffDEuh49XMeta?.alias || [],
    redirect: signupkffDEuh49XMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xHj4i199pzMeta?.name ?? undefined,
    path: _91id_93xHj4i199pzMeta?.path ?? "/virtualservers/:id()",
    meta: _91id_93xHj4i199pzMeta || {},
    alias: _91id_93xHj4i199pzMeta?.alias || [],
    redirect: _91id_93xHj4i199pzMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id].vue").then(m => m.default || m),
    children: [
  {
    name: backupsQkeufevDCHMeta?.name ?? "virtualservers-id-backups",
    path: backupsQkeufevDCHMeta?.path ?? "backups",
    meta: backupsQkeufevDCHMeta || {},
    alias: backupsQkeufevDCHMeta?.alias || [],
    redirect: backupsQkeufevDCHMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/backups.vue").then(m => m.default || m)
  },
  {
    name: basicauthsn7n6KNsygAMeta?.name ?? "virtualservers-id-basicauths",
    path: basicauthsn7n6KNsygAMeta?.path ?? "basicauths",
    meta: basicauthsn7n6KNsygAMeta || {},
    alias: basicauthsn7n6KNsygAMeta?.alias || [],
    redirect: basicauthsn7n6KNsygAMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/basicauths.vue").then(m => m.default || m)
  },
  {
    name: cronjobsuo1B31S5mRMeta?.name ?? "virtualservers-id-cronjobs",
    path: cronjobsuo1B31S5mRMeta?.path ?? "cronjobs",
    meta: cronjobsuo1B31S5mRMeta || {},
    alias: cronjobsuo1B31S5mRMeta?.alias || [],
    redirect: cronjobsuo1B31S5mRMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/cronjobs.vue").then(m => m.default || m)
  },
  {
    name: databasesEyI87ug9pOMeta?.name ?? "virtualservers-id-databases",
    path: databasesEyI87ug9pOMeta?.path ?? "databases",
    meta: databasesEyI87ug9pOMeta || {},
    alias: databasesEyI87ug9pOMeta?.alias || [],
    redirect: databasesEyI87ug9pOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/databases.vue").then(m => m.default || m)
  },
  {
    name: emaillistsFTqcIzImIDMeta?.name ?? "virtualservers-id-emaillists",
    path: emaillistsFTqcIzImIDMeta?.path ?? "emaillists",
    meta: emaillistsFTqcIzImIDMeta || {},
    alias: emaillistsFTqcIzImIDMeta?.alias || [],
    redirect: emaillistsFTqcIzImIDMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/emaillists.vue").then(m => m.default || m)
  },
  {
    name: emailsMNuNNX6u4KMeta?.name ?? "virtualservers-id-emails",
    path: emailsMNuNNX6u4KMeta?.path ?? "emails",
    meta: emailsMNuNNX6u4KMeta || {},
    alias: emailsMNuNNX6u4KMeta?.alias || [],
    redirect: emailsMNuNNX6u4KMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/emails.vue").then(m => m.default || m)
  },
  {
    name: ftpsbziApEYntdMeta?.name ?? "virtualservers-id-ftps",
    path: ftpsbziApEYntdMeta?.path ?? "ftps",
    meta: ftpsbziApEYntdMeta || {},
    alias: ftpsbziApEYntdMeta?.alias || [],
    redirect: ftpsbziApEYntdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/ftps.vue").then(m => m.default || m)
  },
  {
    name: index98BJULjDHbMeta?.name ?? "virtualservers-id",
    path: index98BJULjDHbMeta?.path ?? "",
    meta: index98BJULjDHbMeta || {},
    alias: index98BJULjDHbMeta?.alias || [],
    redirect: index98BJULjDHbMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: sshCfJJBOZ84WMeta?.name ?? "virtualservers-id-ssh",
    path: sshCfJJBOZ84WMeta?.path ?? "ssh",
    meta: sshCfJJBOZ84WMeta || {},
    alias: sshCfJJBOZ84WMeta?.alias || [],
    redirect: sshCfJJBOZ84WMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/ssh.vue").then(m => m.default || m)
  },
  {
    name: subdomainsWZLVvb47RjMeta?.name ?? "virtualservers-id-subdomains",
    path: subdomainsWZLVvb47RjMeta?.path ?? "subdomains",
    meta: subdomainsWZLVvb47RjMeta || {},
    alias: subdomainsWZLVvb47RjMeta?.alias || [],
    redirect: subdomainsWZLVvb47RjMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/[id]/subdomains.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexoHxpw8zg3gMeta?.name ?? "virtualservers",
    path: indexoHxpw8zg3gMeta?.path ?? "/virtualservers",
    meta: indexoHxpw8zg3gMeta || {},
    alias: indexoHxpw8zg3gMeta?.alias || [],
    redirect: indexoHxpw8zg3gMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/virtualservers/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IAALiv3YjcMeta?.name ?? undefined,
    path: _91id_93IAALiv3YjcMeta?.path ?? "/vms/:id()",
    meta: _91id_93IAALiv3YjcMeta || {},
    alias: _91id_93IAALiv3YjcMeta?.alias || [],
    redirect: _91id_93IAALiv3YjcMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/[id].vue").then(m => m.default || m),
    children: [
  {
    name: console88vlj67FYxMeta?.name ?? "vms-id-console",
    path: console88vlj67FYxMeta?.path ?? "console",
    meta: console88vlj67FYxMeta || {},
    alias: console88vlj67FYxMeta?.alias || [],
    redirect: console88vlj67FYxMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/console.vue").then(m => m.default || m)
  },
  {
    name: indexnAyMceUnuQMeta?.name ?? "vms-id",
    path: indexnAyMceUnuQMeta?.path ?? "",
    meta: indexnAyMceUnuQMeta || {},
    alias: indexnAyMceUnuQMeta?.alias || [],
    redirect: indexnAyMceUnuQMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: statisticsYzVRzaHbwYMeta?.name ?? "vms-id-statistics",
    path: statisticsYzVRzaHbwYMeta?.path ?? "statistics",
    meta: statisticsYzVRzaHbwYMeta || {},
    alias: statisticsYzVRzaHbwYMeta?.alias || [],
    redirect: statisticsYzVRzaHbwYMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/[id]/statistics.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index8dPmd9SuiQMeta?.name ?? "vms",
    path: index8dPmd9SuiQMeta?.path ?? "/vms",
    meta: index8dPmd9SuiQMeta || {},
    alias: index8dPmd9SuiQMeta?.alias || [],
    redirect: index8dPmd9SuiQMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vms/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93T7jqGRynR7Meta?.name ?? undefined,
    path: _91id_93T7jqGRynR7Meta?.path ?? "/vpns/:id()",
    meta: _91id_93T7jqGRynR7Meta || {},
    alias: _91id_93T7jqGRynR7Meta?.alias || [],
    redirect: _91id_93T7jqGRynR7Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexdOK6fc9BwIMeta?.name ?? "vpns-id",
    path: indexdOK6fc9BwIMeta?.path ?? "",
    meta: indexdOK6fc9BwIMeta || {},
    alias: indexdOK6fc9BwIMeta?.alias || [],
    redirect: indexdOK6fc9BwIMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: networksvKn4dxZXWZMeta?.name ?? "vpns-id-networks",
    path: networksvKn4dxZXWZMeta?.path ?? "networks",
    meta: networksvKn4dxZXWZMeta || {},
    alias: networksvKn4dxZXWZMeta?.alias || [],
    redirect: networksvKn4dxZXWZMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/networks.vue").then(m => m.default || m)
  },
  {
    name: usersJRa2yalCZ7Meta?.name ?? "vpns-id-users",
    path: usersJRa2yalCZ7Meta?.path ?? "users",
    meta: usersJRa2yalCZ7Meta || {},
    alias: usersJRa2yalCZ7Meta?.alias || [],
    redirect: usersJRa2yalCZ7Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/[id]/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexBNzKFczix9Meta?.name ?? "vpns",
    path: indexBNzKFczix9Meta?.path ?? "/vpns",
    meta: indexBNzKFczix9Meta || {},
    alias: indexBNzKFczix9Meta?.alias || [],
    redirect: indexBNzKFczix9Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/client/pages/vpns/index.vue").then(m => m.default || m)
  }
]